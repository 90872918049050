<template>
  <v-main class="main">
    <loader :loading="$auth.loading" />
    <base-header :links="links" :showLanguageSwitcher="false" />
    <v-container class="main-container">
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
    <snackbars-container />
    <Footer />
  </v-main>
</template>

<script>
import BaseHeader from "@/components/base/Header.vue";
import Loader from "@/components/base/Loader.vue";
import SnackbarsContainer from "@/components/snackbar/SnackbarsContainer.vue";
import Footer from "@/components/v2/base/Footer.vue";

export default {
  name: "LandingPageLike",
  components: {
    BaseHeader,
    Loader,
    Footer,
    SnackbarsContainer,
  },
  computed: {
    links() {
      const basicLinks = [
        // { to: { name: "dashboard" }, text: this.$t("dashboard") },
        // { to: { name: "courses" }, text: this.$t("courses") },
        // { to: { name: "exercises" }, text: this.$t("exercises") },
        // { to: { name: "help" }, text: this.$t("help") },
      ];
      return basicLinks;
    },
  },
};
</script>

<style lang="scss">
.main {
  background-color: #fffaf5;
}

.main-container {
  min-height: calc(100vh - 64px - (185px + 48px)); // vh - header - footer with topmargin
  padding: 12px 24px;
}
</style>
